"use client";

import React from "react";
import { Heading, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";

const RoleDetailsForm = ({ onChange }) => {
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    onChange((prevFormValues) => ({ ...prevFormValues, [id]: value }));
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        Tell us about the role.
      </Heading>
      <Flex>
        <FormControl mr="5%">
          <FormLabel htmlFor="companyName" fontWeight={"normal"}>
            Company Name
          </FormLabel>
          <Input id="companyName" onChange={handleInputChange} />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="jobTitle" fontWeight={"normal"}>
            Job Title
          </FormLabel>
          <Input id="jobTitle" onChange={handleInputChange} />
        </FormControl>
      </Flex>
    </>
  );
};

export default RoleDetailsForm;
