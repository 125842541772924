import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";

import { TbWriting } from "react-icons/tb";
import { BsBarChartSteps } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import SideNavBar from "../components/SideNavBar";
import { useNavigate } from "react-router-dom";
import { getRandomSnippet } from "../services/joyService";
import NewReviewButton from "../components/NewReviewButton";

import SummaryTable from "./SummaryTable";

const Dashboard = ({ sessionData }) => {
  const navigate = useNavigate();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [showJoy50Alert, setShowJoy50Alert] = useState(false);
  const [showJoy100Alert, setShowJoy100Alert] = useState(false);
  const [hasShown50Toast, setHasShown50Toast] = useState(false);
  const [hasShown100Toast, setHasShown100Toast] = useState(false);
  const [isExportAlertOpen, setIsExportAlertOpen] = useState(false);

  const [nextRoute, setNextRoute] = useState(null);
  const cancelRef = useRef();
  const exportCancelRef = useRef();

  const onClose = () => {
    setIsAlertOpen(false);
    setNextRoute(null);
  };

  const onExportClose = () => {
    setIsExportAlertOpen(false);
  };

  const onConfirm = () => {
    navigate(nextRoute);
    onClose();
  };

  const handleNavigation = (route) => {
    setNextRoute(route);
    setIsAlertOpen(true);
  };

  const handleExport = () => {
    setIsExportAlertOpen(true);
  };

  const dataRaw = sessionData.responses.Response1;
  const value2 = sessionData.responses.Response2;
  const value3 = sessionData.responses.Response3;

  const data = JSON.parse(dataRaw);

  const jobDesc = sessionData.jobDesc;
  const companyName = sessionData.companyName;

  // const data = JSON.parse(value1);

  const suggestions = JSON.parse(value2);

  const grammar = JSON.parse(value3);
  const grammarCount = grammar.length;
  const suggestionsCount = suggestions.length;

  const toast = useToast();

  const [checkedSuggestionItems, setCheckedSuggestionItems] = useState(
    new Array(suggestions.length).fill(false)
  );

  const handleSuggestionCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedSuggestionItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedSuggestionItems(updatedCheckedItems);
  };

  const [checkedGrammarItems, setCheckedGrammarItems] = useState(
    new Array(grammar.length).fill(false)
  );

  const handleGrammarCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedGrammarItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedGrammarItems(updatedCheckedItems);
  };

  const calculateAverageScore = (data) => {
    if (data.length === 0) return 0;

    const totalScore = data.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.score;
    }, 0);

    const averageScore = totalScore / data.length;
    return Math.round(averageScore);
  };

  const noErrorsMessage =
    "Nice going, there are no grammar errors in your resume.";

  const showTable = grammarCount > 0;

  useEffect(() => {
    const totalCheckboxes = suggestionsCount + grammarCount;

    const totalChecked =
      checkedSuggestionItems.filter((item) => item).length +
      checkedGrammarItems.filter((item) => item).length;

    const ratio = totalChecked / totalCheckboxes;
    const tolerance50 = 0.1; // Define a small tolerance for floating-point comparison
    const tolerance100 = 0.01;

    if (Math.abs(ratio - 0.5) < tolerance50) {
      setShowJoy50Alert(true);
    } else if (Math.abs(ratio - 1) < tolerance100) {
      setShowJoy100Alert(true);
    } else {
      setShowJoy50Alert(false);
      setShowJoy100Alert(false);
    }
  }, [checkedSuggestionItems, checkedGrammarItems]);

  useEffect(() => {
    if (showJoy50Alert && !hasShown50Toast) {
      toast({
        title: "Keep Going!",
        description: getRandomSnippet("changesmadepartial"),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setHasShown50Toast(true);
    }

    if (showJoy100Alert && !hasShown100Toast) {
      toast({
        title: "Success!",
        description: getRandomSnippet("changescomplete"),
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setHasShown100Toast(true);
    }
  }, [
    showJoy50Alert,
    showJoy100Alert,
    hasShown50Toast,
    hasShown100Toast,
    toast,
  ]);

  return (
    <>
      <Box bg={"#f5f5f5"} minH={"100vh"}>
        <Flex direction={{ base: "column", md: "row" }}>
          <SideNavBar />
          <Box id="report" flex="1" bg="gray.50" ml={{ base: 0, md: "250px" }}>
            <Box
              id="header"
              bg="primaryBackground"
              minW={"100%"}
              display="flex"
              justifyContent="space-between"
              // boxShadow={"sm"}
            >
              <Box pr={4}>
                <Heading ml={5} pl={5} pt={5} color={"heading"}>
                  Resume Report
                </Heading>
                <Text
                  fontSize={"18"}
                  fontWeight={"500"}
                  ml={5}
                  pl={5}
                  color={"text"}
                >
                  {jobDesc} | {companyName}
                </Text>
              </Box>
              <Box
                flex="1"
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-start"
                pl={4}
                mr={10}
                pt={8}
              >
                <Button
                  size={"sm"}
                  variant={"primary"}
                  mr={2}
                  onClick={() => handleNavigation("/home")}
                >
                  Home
                </Button>

                <NewReviewButton
                  buttonText={"New Review"}
                  variant={"primary"}
                  mr={2}
                  size="sm"
                />
                <Button
                  size={"sm"}
                  variant={"primary"}
                  mr={2}
                  onClick={handleExport}
                >
                  Export
                </Button>
              </Box>
            </Box>
            <Text fontSize={"14"} ml={5} pl={5} pt={5} mr={10} color="text">
              Tailoring your resume is essential to stand out in the job market.
              By customizing your resume for each job application, you highlight
              the skills and experiences that are most relevant to the specific
              role, showing employers that you are the ideal candidate.
            </Text>
            <Text
              fontSize={"14"}
              color={"text"}
              fontWeight={"700"}
              ml={5}
              pl={5}
              pt={5}
            >
              {getRandomSnippet("reportintrofunny")}
            </Text>

            <AlertDialog
              isOpen={isAlertOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader
                    fontSize="lg"
                    color="header"
                    fontWeight="bold"
                  >
                    Confirm Navigation
                  </AlertDialogHeader>

                  <AlertDialogBody color="text">
                    Are you sure you want to leave this page? Once you leave,
                    this review will be lost.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" onClick={onConfirm} ml={3}>
                      Confirm
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <AlertDialog
              isOpen={isExportAlertOpen}
              leastDestructiveRef={exportCancelRef}
              onClose={onExportClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader
                    color="header"
                    fontSize="lg"
                    fontWeight="bold"
                  >
                    We're working on this!
                  </AlertDialogHeader>
                  <AlertDialogBody color="text">
                    We're diligently working to introduce this feature and many
                    others. Please check back soon.
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={exportCancelRef} onClick={onExportClose}>
                      Close
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>

            <Box
              mx={10}
              my={5}
              bg="white"
              borderRadius="xl"
              p={4}
              boxShadow="md"
              borderWidth={"1px"}
              borderColor={"#f8f8f8"}
            >
              <Stat pb={5}>
                <StatLabel color="heading" fontSize="xl">
                  <BsBarChartSteps />
                  Key Skills and Education
                </StatLabel>
                <StatNumber color="text" fontSize="xx-large">
                  {calculateAverageScore(data)}%
                </StatNumber>
              </Stat>
              <SummaryTable data={data} />
            </Box>

            <Box
              mx={10}
              my={5}
              bg="white"
              borderRadius="xl"
              p={4}
              boxShadow="md"
              borderWidth={"1px"}
              borderColor={"#f8f8f8"}
            >
              <Stat pb={5}>
                <StatLabel color="heading" fontSize="xl">
                  <BiTargetLock size={25} />
                  Tailoring Suggestions
                </StatLabel>
                <StatNumber color="text" fontSize="xx-large">
                  {suggestionsCount}
                </StatNumber>
              </Stat>
              <Table variant="simple">
                <Tbody>
                  {suggestions.map((suggestion, index) => (
                    <Tr key={index}>
                      <Td borderColor="#e2e2e2">
                        <Checkbox
                          isChecked={checkedSuggestionItems[index]}
                          onChange={() => handleSuggestionCheckboxChange(index)}
                        />
                      </Td>
                      <Td
                        borderColor="#e2e2e2"
                        sx={{
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                          textDecoration: checkedSuggestionItems[index]
                            ? "line-through"
                            : "none",
                          color: checkedSuggestionItems[index]
                            ? "#bebebe"
                            : "text",
                        }}
                      >
                        {suggestion}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>

            <Box
              bg="white"
              borderRadius="xl"
              my={10}
              mx={10}
              p={4}
              boxShadow="md"
              borderWidth={"1px"}
              borderColor={"#f8f8f8"}
            >
              <Stat pb={5}>
                <StatLabel color="heading" fontSize="xl">
                  <TbWriting size={25} />
                  Grammar
                </StatLabel>
                <StatNumber color="text" fontSize="xx-large">
                  {grammarCount}
                </StatNumber>
              </Stat>
              {showTable ? (
                <Table variant="simple">
                  <Tbody>
                    {grammar.map((item, index) => (
                      <Tr key={index}>
                        <Td borderColor="#e2e2e2">
                          <Checkbox
                            isChecked={checkedGrammarItems[index]}
                            onChange={() => handleGrammarCheckboxChange(index)}
                          />
                        </Td>
                        <Td
                          borderColor="#e2e2e2"
                          sx={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            textDecoration: checkedGrammarItems[index]
                              ? "line-through"
                              : "none",
                            color: checkedGrammarItems[index]
                              ? "#bebebe"
                              : "text",
                          }}
                        >
                          {item}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text color="text" fontSize="">
                  {noErrorsMessage}
                </Text>
              )}
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Dashboard;
