"use client";

import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { CheckCircleIcon, WarningTwoIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

export default function Result({ result, type }) {
  const navigate = useNavigate();
  let headline = "";
  let body = "";

  if (result === "success" && type === "payment") {
    headline = "Refill Successful";
    body = "Your newly purchased reviews have been added to your account.";
  } else if (result === "cancellation" && type === "payment") {
    headline = "Refill Cancelled";
    body =
      "Your purchase has been cancelled, and no charges have been made to your method of payment. If you require assistance, please contact us.";
  } else if (result === "failure" && type === "payment") {
    headline = "Refill Failed";
    body =
      "There has been an issue with your purchase, and no charges have been made to your method of payment. If you require assistance, please contact us.";
  } else if (result === "failure" && type === "other") {
    headline = "Something went wrong";
    body =
      "We ran into an issue. Please try again, and if the issue persists, reach out for assitance.";
  }

  return (
    <>
      <Box
        minH={"100vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={"primaryBackground"}
      >
        <Box textAlign="center" py={10} px={20}>
          {result === "success" && (
            <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
          )}
          {result === "cancellation" && (
            <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
          )}
          {result === "failure" && (
            <Box display="inline-block">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={"red.500"}
                rounded={"50px"}
                w={"55px"}
                h={"55px"}
                textAlign="center"
              >
                <CloseIcon boxSize={"20px"} color={"white"} />
              </Flex>
            </Box>
          )}
          <Heading as="h2" size="xl" mt={6} mb={2} color={"heading"}>
            {headline}
          </Heading>
          <Text color={"text"}>{body}</Text>
          <Box>
            <Button
              mt={10}
              mr={2}
              variant="primary"
              onClick={() => navigate("/home")}
            >
              Home
            </Button>
            <Button
              mt={10}
              ml={2}
              variant="secondary"
              onClick={() => navigate("/contact")}
            >
              Help
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
