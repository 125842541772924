import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Progress,
  Text,
  Flex,
} from "@chakra-ui/react";
import CustomBadge from "./Badge";

const SummaryTable = ({ data }) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead borderColor="red">
          <Tr>
            <Th borderColor={"#bebebe"}>Skill</Th>
            <Th borderColor={"#bebebe"}>Keywords</Th>
            <Th borderColor={"#bebebe"}>Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => (
            <Tr key={index}>
              <Td borderColor="#e2e2e2" fontSize={"sm"} fontWeight={"600"}>
                {item.skill}
              </Td>
              <Td borderColor="#e2e2e2">
                {item.keywords.map((keywordObj, idx) => (
                  <CustomBadge key={idx} appears={keywordObj.appears}>
                    {keywordObj.keyword}
                  </CustomBadge>
                ))}
              </Td>
              <Td borderColor="#e2e2e2" minWidth="300px">
                <Flex align="center">
                  <Box width="250px" mr={2}>
                    <Progress
                      borderRadius="lg"
                      value={item.score}
                      size="sm"
                      colorScheme="red"
                    />
                  </Box>
                  <Text width="50px" textAlign="right">
                    {item.score}%
                  </Text>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
