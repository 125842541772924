import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { CheckIcon } from "@chakra-ui/icons";

const VerificationModal = ({ isOpen, onClose }) => {
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const checkVerificationStatus = async () => {
      const user = auth.currentUser;

      if (user) {
        await user.reload();

        if (user.emailVerified) {
          setVerified(true);
          onClose();
          navigate("/home");
        } else {
          setVerified(false);
        }
      }
    };

    // Poll for verification status every few seconds
    if (isOpen) {
      const interval = setInterval(checkVerificationStatus, 3000);
      return () => clearInterval(interval);
    }
  }, [auth, navigate, onClose, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Email Verification</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <Text>
            We've sent you a verification email. Please check your inbox or junk
            folder, and follow the instructions to verify your email address.
          </Text>
          <Box mt={4} display="flex" alignItems="center">
            {!verified ? (
              <Spinner size="sm" color="primaryHighlight" mr={2} />
            ) : (
              verified && <CheckIcon color="green.500" mr={2} />
            )}
            <Text>
              {verified
                ? "Email verified! Redirecting..."
                : "Waiting for email verification..."}
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VerificationModal;
