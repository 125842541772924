import React, { useState } from "react";
import { Box, Tooltip } from "@chakra-ui/react";

const CustomBadge = ({ children, colorScheme = "gray", appears }) => {
  const colors = {
    gray: {
      bg: "gray.200",
      color: "gray.800",
      bgDark: "gray.400",
      colorDark: "gray.900",
    },
    red: {
      bg: "red.200",
      color: "red.800",
      bgDark: "red.400",
      colorDark: "red.900",
    },
    green: {
      bg: "green.200",
      color: "green.800",
      bgDark: "green.400",
      colorDark: "green.900",
    },
    blue: {
      bg: "blue.200",
      color: "blue.800",
      bgDark: "blue.400",
      colorDark: "blue.900",
    },
    // Add more color schemes as needed
  };

  const { bg, color, bgDark, colorDark } = colors[colorScheme] || colors.gray;

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <Tooltip
      label={
        appears
          ? "This keyword appears in your resume"
          : "This keyword does not appear in your resume"
      }
    >
      <Box
        display="inline-block"
        px="0.4em"
        ml={1}
        py="0.4em"
        fontSize="0.8em"
        fontWeight="800"
        lineHeight="1"
        textAlign="center"
        whiteSpace="nowrap"
        verticalAlign="baseline"
        // borderRadius="full"
        // bg={isClicked ? bgDark : bg}
        bg={appears ? "#c6f6d5" : "#fed7d7"}
        // color={isClicked ? colorDark : color}
        color={appears ? "#38543d" : "#822776"}
        onClick={handleClick}
        cursor="pointer"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default CustomBadge;
