"use client";

import React from "react";
import {
  Heading,
  FormControl,
  GridItem,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";

const JobDescriptionForm = ({ onChange }) => {
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    onChange((prevFormValues) => ({ ...prevFormValues, [id]: value }));
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        What is the job looking for?
      </Heading>
      <FormControl as={GridItem} colSpan={6}>
        <FormLabel
          htmlFor="job-description"
          fontSize="sm"
          fontWeight="md"
          // color="white"
          mt="2%"
        >
          Job Description
        </FormLabel>
        <Textarea
          id="jobDescription"
          placeholder="Paste the entire job description here. "
          sx={{
            "::placeholder": {
              color: "darkGray", // Use your desired color here
            },
          }}
          rows={15}
          fontSize={{
            sm: "sm",
          }}
          borderColor="lightGray"
          focusBorderColor="primaryHighlight"
          onChange={handleInputChange}
        />
      </FormControl>
    </>
  );
};

export default JobDescriptionForm;
