import { useState, useEffect, useCallback } from "react";
import { db, auth } from "../configuration/firebase";
import {
  collection,
  query,
  orderBy,
  getDocs,
  limit,
  startAfter,
} from "firebase/firestore";

const useUserReviews = (pageSize = 3) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchUserReviews = useCallback(
    async (lastVisible) => {
      const user = auth.currentUser;

      if (user) {
        const uid = user.uid;
        try {
          let userReviewsQuery = query(
            collection(db, `users/${uid}/reviews`),
            orderBy("createdAt", "desc"),
            limit(pageSize)
          );

          if (lastVisible) {
            userReviewsQuery = query(userReviewsQuery, startAfter(lastVisible));
          }

          const reviewsSnapshot = await getDocs(userReviewsQuery);
          const reviewsList = reviewsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setReviews((prevReviews) => [...prevReviews, ...reviewsList]);
          setLastVisible(reviewsSnapshot.docs[reviewsSnapshot.docs.length - 1]);
          setHasMore(reviewsList.length === pageSize);
        } catch (error) {
          console.error("Error fetching user reviews:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        console.error("No user is signed in");
      }
    },
    [pageSize]
  );

  useEffect(() => {
    setLoading(true);
    fetchUserReviews(null);
  }, [fetchUserReviews]);

  return {
    reviews,
    loading,
    hasMore,
    fetchMore: () => fetchUserReviews(lastVisible),
  };
};

export default useUserReviews;
