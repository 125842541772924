"use client";

import React, { useState, useRef } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  Checkbox,
  Divider,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { UserAuth } from "../../contexts/AuthContext";
import Banner from "../../components/Banner";
import GoogleSignInButton from "../../components/GoogleSignInButton";
import VerificationModal from "../../components/EmailVerificationModal";
import * as Yup from "yup";
import { parseError } from "../../tools/FirebaseErrorParser";

import logo from "../../assets/resumiq-orange-black-vertical.png";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [firebaseError, setFirebaseError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const { createUser } = UserAuth();

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    termsAndConditions: Yup.boolean()
      .oneOf([true], "You must accept the Terms and Conditions")
      .required("You must accept the Terms and Conditions"),
  });

  const register = async (values, actions) => {
    try {
      const userCredential = await createUser(
        values.firstName,
        values.lastName,
        values.email,
        values.password
      );

      onOpen();

      actions.setSubmitting(false);
    } catch (e) {
      console.error(e);
      setFirebaseError(parseError(e.message));
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Banner />
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg="primaryBackground"
      >
        <VerificationModal isOpen={isOpen} onClose={onClose} />
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"} textAlign={"center"}>
            <Box as="button" onClick={() => navigate("/")} cursor="pointer">
              <Image src={logo} width="200px" py={2} />
            </Box>
            <Heading fontSize={"xl"} textAlign={"center"} color="heading">
              Create an account
            </Heading>
            <Text textAlign="center" color="text" fontSize="sm">
              Or{" "}
              <Link
                onClick={() => navigate("/signin")}
                color="text"
                textDecoration={"underline"}
                // fontWeight="bold"
                _hover={{ textDecoration: "none" }}
              >
                sign in to your account
              </Link>
            </Text>
          </Stack>
          <Box
            bg="secondaryBackground"
            p={8}
            borderWidth={"1px"}
            borderColor={"#f8f8f8"}
            boxShadow="md"
            borderRadius="xl"
          >
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                termsAndConditions: false,
              }}
              validationSchema={validationSchema}
              onSubmit={register}
            >
              {({ isSubmitting }) => (
                <Form>
                  {firebaseError && (
                    <Box mb={4}>
                      <Text color="#E53A5A" fontSize="sm">
                        {firebaseError}
                      </Text>
                    </Box>
                  )}

                  <GoogleSignInButton text="Sign up with Google" />
                  <Flex align="center" pb={8}>
                    <Divider borderColor="gray.300" />
                    <Text px={2} color="gray.500" fontWeight="medium">
                      or
                    </Text>
                    <Divider borderColor="gray.300" />
                  </Flex>
                  <Box mb={4}>
                    <Flex justify="space-between">
                      <Box mr={2}>
                        <Field name="firstName">
                          {({ field }) => (
                            <FormControl id="firstName">
                              <FormLabel color={"text"}>First Name</FormLabel>
                              <Input type="text" {...field} color={"text"} />
                              <ErrorMessage name="firstName">
                                {(msg) => (
                                  <div
                                    style={{
                                      color: "#E53A5A",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box ml={2}>
                        <Field name="lastName">
                          {({ field }) => (
                            <FormControl id="lastName">
                              <FormLabel color={"text"}>Last Name</FormLabel>
                              <Input type="text" {...field} color={"text"} />
                              <ErrorMessage name="lastName">
                                {(msg) => (
                                  <div
                                    style={{
                                      color: "#E53A5A",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                    </Flex>
                  </Box>
                  <Box mb={4}>
                    <Field name="email">
                      {({ field }) => (
                        <FormControl id="email">
                          <FormLabel color={"text"}>Email address</FormLabel>
                          <Input type="email" {...field} color={"text"} />
                          <ErrorMessage name="email">
                            {(msg) => (
                              <div
                                style={{ color: "#E53A5A", fontSize: "14px" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box mb={4}>
                    <Field name="password">
                      {({ field }) => (
                        <FormControl id="password">
                          <FormLabel color={"text"}>Password</FormLabel>
                          <InputGroup>
                            <Input
                              type={showPassword ? "text" : "password"}
                              {...field}
                              color={"text"}
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                variant={"outlineNoBorder"}
                                onClick={() =>
                                  setShowPassword(
                                    (showPassword) => !showPassword
                                  )
                                }
                              >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <ErrorMessage name="password">
                            {(msg) => (
                              <div
                                style={{ color: "#E53A5A", fontSize: "14px" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box mb={4}>
                    <Field name="termsAndConditions">
                      {({ field }) => (
                        <FormControl id="termsAndConditions">
                          <Stack direction="row" alignItems="center">
                            <Checkbox
                              borderColor={"black"}
                              colorScheme="green"
                              {...field}
                            />
                            <FormLabel
                              color={"text"}
                              htmlFor="termsAndConditions"
                              mt={2}
                            >
                              I agree to the{" "}
                              <Button
                                as={"a"}
                                href={"https://resumiq.ai/terms"}
                                variant="link"
                                color={"text"}
                                target="_blank" // Open link in a new tab
                                rel="noopener noreferrer" // Security measure
                              >
                                Terms and Conditions
                              </Button>
                            </FormLabel>
                          </Stack>
                          <ErrorMessage name="termsAndConditions">
                            {(msg) => (
                              <div
                                style={{ color: "#E53A5A", fontSize: "14px" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Stack spacing={10} pt={2}>
                    <Button
                      size="lg"
                      variant={"primary"}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Sign up
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
