"use client";

import React, { useState } from "react";
import { useSessionContext } from "../contexts/SessionContext";
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import RoleDetailsForm from "../components/forms/RoleDetailsForm";
import JobDescriptionForm from "../components/forms/JobDescriptionForm";
import ResumeForm from "../components/forms/ResumeForm";
import SideNavBar from "../components/SideNavBar";
import { apiUrl } from "../config";

export default function Multistep() {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);
  const [form1Values, setForm1Values] = useState({});
  const [form2Values, setForm2Values] = useState({});
  const [form3Values, setForm3Values] = useState({});
  const { updateSessionData, clearSessionData } = useSessionContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalHeader, setModalHeader] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [primaryModalButton, setPrimaryModalButton] = useState("");
  const [secondaryModalButton, setSecondaryModalButton] = useState("");
  const [isPrimaryModalButton, setIsPrimaryModalButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const idToken = sessionStorage.getItem("idToken");
  const uid = sessionStorage.getItem("uid");

  const checkCredits = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/checkCredits?uid=${uid}`,

        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.data.permitted === "yes") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      navigate("/generalerror");
      return false;
    }
  };

  const saveReview = async () => {
    try {
      await axios.post(
        `${apiUrl}/saveReview`,
        {
          uid: uid,
          companyName: form1Values.companyName,
          jobTitle: form1Values.jobTitle,
          jobDescription: form2Values.jobDescription,
          resume: form3Values.resume,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (e) {}
  };

  const ask2 = async () => {
    const requestBodies = [
      {
        uid: uid,
        section: 1,
        companyName: form1Values.companyName,
        jobTitle: form1Values.jobTitle,
        jobDescription: form2Values.jobDescription,
        resume: form3Values.resume,
      },
      {
        uid: uid,
        section: 2,
        companyName: form1Values.companyName,
        jobTitle: form1Values.jobTitle,
        jobDescription: form2Values.jobDescription,
        resume: form3Values.resume,
      },
      {
        uid: uid,
        section: 3,
        companyName: form1Values.companyName,
        jobTitle: form1Values.jobTitle,
        jobDescription: form2Values.jobDescription,
        resume: form3Values.resume,
      },

      // {
      //   uid: uid,
      //   section: 5,
      //   companyName: form1Values.companyName,
      //   jobTitle: form1Values.jobTitle,
      //   jobDescription: form2Values.jobDescription,
      //   resume: form3Values.resume,
      // },
      // {
      //   uid: uid,
      //   section: 4,
      //   companyName: form1Values.companyName,
      //   jobTitle: form1Values.jobTitle,
      //   jobDescription: form2Values.jobDescription,
      //   resume: form3Values.resume,
      // },
    ];

    const allResponses = {};

    try {
      await Promise.all(
        requestBodies.map(async (body, index) => {
          try {
            const response = await axios.post(`${apiUrl}/queryOpenAi`, body, {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            });
            allResponses[`Response${index + 1}`] = response.data.aiFeedback;
          } catch (error) {
            console.error(`Error in request ${index + 1}:`, error);
            navigate("/generalerror");
          }
        })
      );

      // All requests have been completed

      updateSessionData({
        jobDesc: form1Values.jobTitle,
        companyName: form1Values.companyName,
        responses: allResponses,
      });

      // `allResponses` now contains all the responses in a single JSON object
      // console.log("All Responses:", allResponses);
    } catch (error) {
      console.error("Error:", error);
      navigate("/generalerror");
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (
      form1Values.companyName === undefined ||
      form1Values.jobTitle === undefined ||
      form2Values.jobDescription === undefined ||
      form3Values.resume === undefined ||
      form1Values.companyName === "" ||
      form1Values.jobTitle === "" ||
      form2Values.jobDescription === "" ||
      form3Values.resume === ""
    ) {
      setModalHeader("Mandatory Fields Empty");
      setModalBody(
        `Oops! It looks like you've left one or more form fields empty. Please fill out all sections of the form to proceed.`
      );
      // setPrimaryModalButton("Top up now");
      setSecondaryModalButton("Close");
      setIsPrimaryModalButton(false);
      onOpen();
    } else {
      const permitted = await checkCredits();

      if (permitted) {
        // console.log("YOU ARE ALLOWED!");
        clearSessionData();
        navigate("/report");
        ask2();
        saveReview();
        setIsLoading(false);
      } else {
        // console.log("YOU ARE NOT ALLOWED!");
        setModalHeader("Insufficent Review Credits");
        setModalBody(`Oops! It looks like you've run out of reviews. Don't
        worry, you can easily top up your balance to continue
        enjoying our product.`);
        setPrimaryModalButton("Top up now");
        setSecondaryModalButton("Close");
        setIsPrimaryModalButton(true);
        onOpen();
      }
    }
  };

  const handleForm1Change = (values) => {
    setForm1Values(values);
  };

  const handleForm2Change = (values) => {
    setForm2Values(values);
  };

  const handleForm3Change = (values) => {
    setForm3Values(values);
  };

  return (
    <>
      <Flex direction={{ base: "column", md: "row" }}>
        <SideNavBar />
        {/* <Navbar /> */}
        <Box bg="primaryBackground" minH="100vh" py={8} flex="1">
          <Box
            rounded="lg"
            shadow="1px 1px 3px rgba(0,0,0,0.3)"
            maxWidth={800}
            p={6}
            m="10px auto"
            as="form"
            bg="secondaryBackground"
            textColor={"text"}
          >
            <Progress
              // hasStripe
              borderRadius={"xl"}
              value={progress}
              variant={"determinate"}
              mb="5%"
              mx="5%"
              isAnimated
              colorScheme="primaryHighlight"
            ></Progress>
            {step === 1 ? (
              <RoleDetailsForm onChange={handleForm1Change} />
            ) : step === 2 ? (
              <JobDescriptionForm onChange={handleForm2Change} />
            ) : (
              <ResumeForm onChange={handleForm3Change} />
            )}
            <ButtonGroup mt="5%" w="100%">
              <Flex w="100%" justifyContent="space-between">
                <Flex>
                  <Button
                    onClick={() => {
                      setStep(step - 1);
                      setProgress(progress - 33.33);
                    }}
                    isDisabled={step === 1}
                    variant="primary"
                    w="7rem"
                    mr="5%"
                  >
                    Back
                  </Button>
                  <Button
                    w="7rem"
                    isDisabled={step === 3}
                    onClick={() => {
                      setStep(step + 1);
                      if (step === 3) {
                        setProgress(100);
                      } else {
                        setProgress(progress + 33.33);
                      }
                    }}
                    variant="outline"
                  >
                    Next
                  </Button>
                </Flex>
                {step === 3 ? (
                  <>
                    <Button
                      disabled={isLoading}
                      w="7rem"
                      variant="secondary"
                      onClick={handleSubmit}
                    >
                      {isLoading ? (
                        <Box display="flex" alignItems="center">
                          <Spinner size="sm" mr="2" />
                        </Box>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Modal isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{modalHeader}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>{modalBody}</ModalBody>

                        <ModalFooter>
                          {isPrimaryModalButton && (
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={() => navigate("/pricing")}
                            >
                              {primaryModalButton}
                            </Button>
                          )}
                          <Button variant="ghost" onClick={onClose}>
                            {secondaryModalButton}
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </>
                ) : null}
              </Flex>
            </ButtonGroup>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
