import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import useUserReviews from "../hooks/useUserReviews";

const UserReviewsTable = () => {
  const { reviews, loading, hasMore, fetchMore } = useUserReviews();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  return (
    <Box>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Company Name</Th>
            <Th>Role</Th>
            <Th>Created At</Th>
          </Tr>
        </Thead>
        <Tbody>
          {reviews.map((review) => (
            <Tr key={review.id}>
              <Td>{review.company}</Td>
              <Td>{review.role}</Td>
              <Td>{formatDate(review.createdAt)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Spinner size="xl" />
        </Box>
      )}
      {!loading && reviews.length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Text>You have not done any reviews yet.</Text>
        </Box>
      )}
      {!loading && hasMore && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <Button onClick={fetchMore}>Load More</Button>
        </Box>
      )}
    </Box>
  );
};

export default UserReviewsTable;
