import React, { useState, useEffect } from "react";

import { Box, Text, Spinner } from "@chakra-ui/react";

import { useSessionContext } from "../contexts/SessionContext";
import { apiUrl } from "../config";
import axios from "axios";
import Navbar from "../components/Navbar";
import Dashboard from "../components/Dashboard";
import { db } from "../configuration/firebase";
import { doc, getDoc } from "firebase/firestore";
import { getRandomSnippet } from "../services/joyService";

export default function Report() {
  const { sessionData } = useSessionContext();
  const [emailSent, setEmailSent] = useState(false);
  const idToken = sessionStorage.getItem("idToken");

  useEffect(() => {
    if (sessionData && !emailSent) {
      const value1 = sessionData.responses.Response1;
      const value2 = sessionData.responses.Response2;
      const value3 = sessionData.responses.Response3;

      const calculateAverageScore = (data) => {
        if (data.length === 0) return 0;

        const totalScore = data.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.score;
        }, 0);

        const averageScore = totalScore / data.length;
        return Math.round(averageScore);
      };

      function finalizeGrammar(grammar) {
        if (grammar.length === 0) {
          grammar.push(
            "Nice going, there are no grammar errors in your resume."
          );
        }
        return grammar;
      }

      const data = JSON.parse(value1);
      const scoreOverall = calculateAverageScore(data);
      const suggestions = JSON.parse(value2);
      const grammarRaw = JSON.parse(value3);
      const grammar = finalizeGrammar(grammarRaw);

      const uid = sessionStorage.getItem("uid");

      const fetchUserData = async () => {
        try {
          const docRef = doc(db, "users", uid); // Assuming "users" is the collection name
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const reportSetting = userData.notifications?.report ?? true; // Default to true if not set

            if (reportSetting) {
              try {
                const response = await axios.post(
                  `${apiUrl}/sendEmailReport`,
                  {
                    uid: uid,
                    data: {
                      jobDesc: sessionData.jobDesc,
                      companyName: sessionData.companyName,
                      title: "Resume report",
                      scoreOverall: scoreOverall,
                      suggestions: suggestions,
                      grammar: grammar,
                      scores: data,
                    },
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${idToken}`,
                    },
                  }
                );

                setEmailSent(true); // Set the state to true after sending the email
              } catch (error) {
                console.error("Error sending email report:", error);
              }
            }

            return reportSetting;
          } else {
            console.error("No such document!");
            return null;
          }
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      };

      fetchUserData();
    }
  }, [sessionData, emailSent]);

  if (!sessionData) {
    return (
      <Box bg="primaryBackground">
        <Navbar />

        <Box
          bg="primaryBackground"
          h="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" color="primaryHighlight" />
          <Text fontSize={25} fontWeight={"600"} pt="10px" color="text">
            {/* Analyzing your resume... */}
            {getRandomSnippet("analyzingmessage")}
          </Text>
          <Text color="text">
            Please be patient, this process may take a few moments.
          </Text>
        </Box>
      </Box>
    );
  } else {
    return <Dashboard sessionData={sessionData} />;
  }
}
