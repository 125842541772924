"use client";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useDisclosure,
  Stack,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuDivider,
  MenuItem,
  Center,
  Heading,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import { db } from "../configuration/firebase";
import { doc, getDoc } from "firebase/firestore";
// import logo from "../assets/revyoo-purple.png";
import logo from "../assets/resumiq-orange-black.png";

export default function Navbar({ backgroundColor, showInternal }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, logout } = UserAuth();
  const [firstName, setFirstName] = useState("");
  const [credits, setCredits] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Pricing",
      link: "/pricing",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  const handleLogout = () => {
    logout();
    // navigate("/signIn");
  };
  const uid = user?.uid || sessionStorage.getItem("uid");

  useEffect(() => {
    if (uid) {
      const getUserInfo = async (uid) => {
        try {
          const docref = doc(db, "users", uid);
          const docSnap = await getDoc(docref);
          const userData = docSnap.data();
          if (
            userData &&
            userData.firstName &&
            userData.credits &&
            userData.email
          ) {
            setFirstName(userData.firstName);
            setCredits(userData.credits);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
          // navigate("/generalerror");
        }
      };
      // sessionStorage.setItem("uid", uid);
      const fetchUserInfoWithDelay = async () => {
        await getUserInfo(uid);
      };

      fetchUserInfoWithDelay();
    }
  }, [uid, location.pathname]);

  if (!user || showInternal == "false") {
    return (
      <>
        <Box bg={backgroundColor || "primaryBackground"} px={4}>
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={"center"}>
              <Box as="button" onClick={() => navigate("/")} cursor="pointer">
                <Image src={logo} width={"200px"} />
              </Box>
            </HStack>
            <HStack
              pl={20}
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
            >
              {links.map((link) => (
                <Button
                  variant={"navLanding"}
                  fontWeight={400}
                  onClick={() => navigate(link.link)}
                >
                  {link.name}
                </Button>
              ))}
            </HStack>
            <Flex alignItems={"center"}>
              <Stack
                flex={{ base: 1, md: 0 }}
                justify={"flex-end"}
                direction={"row"}
                spacing={6}
              >
                <Button
                  as={"a"}
                  color={"black"}
                  fontSize={"sm"}
                  fontWeight={400}
                  variant={"link"}
                  onClick={() => {
                    navigate("/signIn");
                  }}
                >
                  Sign In
                </Button>
                <Button
                  as={"a"}
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  bg={"pink.400"}
                  _hover={{
                    bg: "pink.300",
                  }}
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Get Started
                </Button>
              </Stack>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: "none" }}>
              <Stack as={"nav"} spacing={4}>
                {links.map((link) => (
                  <Button
                    variant={"navLanding"}
                    key={link.link}
                    onClick={() => navigate(link.link)}
                    fontWeight={400}
                  >
                    {link.name}
                  </Button>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      </>
    );
  } else {
    const fullName =
      sessionStorage.getItem("firstName") +
      " " +
      sessionStorage.getItem("lastName");
    return (
      <>
        <Box bg={backgroundColor || "primaryBackground"} px={4}>
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
            <Flex spacing={8} alignItems={"center"}>
              <Box
                as="button"
                onClick={() => navigate("/home")}
                cursor="pointer"
              >
                <Image src={logo} width="200px" />
              </Box>
            </Flex>

            <Flex alignItems={"center"}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    style={{ textDecoration: "none" }}
                    name={fullName}
                    src="your-profile-url.jpg"
                    size={"sm"}
                  />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      style={{ textDecoration: "none" }}
                      name={fullName}
                      src="your-profile-url.jpg"
                      size={"lg"}
                    />
                  </Center>
                  <br />
                  <Center>
                    <Heading fontSize={30}>{firstName}</Heading>
                  </Center>
                  <Center>
                    <p>{credits} Reviews</p>
                  </Center>

                  <br />
                  <MenuDivider />
                  <MenuItem
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    Home
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      navigate("/settings");
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/contact");
                    }}
                  >
                    Support
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate("/tools");
                    }}
                  >
                    Tools
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: "none" }}>
              <Stack as={"nav"} spacing={4}>
                <Button variant={"nav"} fontWeight={400}>
                  {links[0].name} {/* Render the name of the first element */}
                </Button>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </>
    );
  }
}
