"use client";

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from "@chakra-ui/react";
import { FaLinkedinIn } from "react-icons/fa";

import { Link } from "react-router-dom";
import logo from "../assets/resumiq-orange-black.png";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <>
      <Box bg={"secondaryBackground"} color={"text"} pt={10}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          spacing={4}
          justify={"center"}
          align={"center"}
        >
          <Image src={logo} width="150px" />
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={6}
            alignItems={"center"}
          >
            <Link to="/home">
              <Box as="a">Home</Box>
            </Link>
            <Link to="https://resumiq.ai/pricing">
              <Box as="a">Pricing</Box>
            </Link>

            <Link to="https://resumiq.ai/privacy">
              <Box as="a">Privacy Policy</Box>
            </Link>
            <Link to="https://resumiq.ai/terms">
              <Box as="a">Terms and Conditions</Box>
            </Link>
            <Link to="https://resumiq.ai/system-status">
              <Box as="a">System Status</Box>
            </Link>
          </Stack>
          <SocialButton
            label={"LinkedIn"}
            href={"https://www.linkedin.com/company/Resumiq"}
          >
            <FaLinkedinIn />
          </SocialButton>
          <Text>© 2024 Resumiq. All rights reserved</Text>
        </Container>
      </Box>
    </>
  );
}
