import React, { useState } from "react";
import { Box, Container, IconButton, Stack, Text } from "@chakra-ui/react";
import { FiX } from "react-icons/fi";

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box borderBottomWidth="1px" bg="#ffd1bd" maxW="100%">
      <Container py={{ base: "4", md: "3.5" }} maxWidth="100%">
        <Stack
          maxW={"100%"}
          direction="row"
          spacing={{ base: "3", md: "4" }}
          justify="space-between"
          align={{ base: "start", md: "center" }}
        >
          <Box flex="1" pr={{ base: "0", md: "4" }}>
            <Text
              fontWeight="700"
              fontSize={{ base: "md", md: "lg" }}
              textAlign={"center"}
            >
              👀 Welcome to Our New Look!
            </Text>
            <Text
              color="fg.muted"
              fontSize={{ base: "sm", md: "md" }}
              textAlign={"center"}
            >
              We've rebranded and you're now on our new site. While things may
              look a bit different, rest assured that the app you love remains
              the same!
            </Text>
          </Box>
          <IconButton
            icon={<FiX />}
            variant="tertiary"
            aria-label="Close banner"
            onClick={handleClose}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default Banner;
