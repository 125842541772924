import React from "react";
import { Box, Text, Icon, Button, Flex } from "@chakra-ui/react";
import { MdBuild } from "react-icons/md";
import SideNavBar from "../components/SideNavBar";
import { UserAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Tools = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  return (
    <Box bg={"primaryBackground"} minH={"100vh"} color={"text"}>
      <Flex direction={{ base: "column", md: "row" }}>
        <SideNavBar />
        <Box
          flex="1"
          bg="primaryBackground"
          ml={{ base: 0, md: "250px" }}
          p={10}
        >
          <Box textAlign="center" mt={8} bg="pr">
            <Icon as={MdBuild} boxSize={12} color="gray.500" />
            <Text fontSize="xl" fontWeight="bold" mt={4} mb={4}>
              Sorry, this page is currently under construction.
            </Text>
            {user ? (
              <Button
                onClick={() => {
                  navigate("/home");
                }}
              >
                Home
              </Button>
            ) : (
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Button>
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Tools;
