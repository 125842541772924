import { useEffect, useState } from "react";
import { db } from "../configuration/firebase"; // Custom hook to get the authenticated user
import { doc, getDoc } from "firebase/firestore";

const useTermsCheck = () => {
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  const uid = sessionStorage.getItem("uid");

  useEffect(() => {
    const checkTermsAccepted = async () => {
      if (uid) {
        try {
          const userDocRef = doc(db, "users", uid);
          const userDoc = await getDoc(userDocRef);
          const termsAccepted = userDoc.data()?.termsAccepted;

          if (!termsAccepted) {
            setShowTermsDialog(true);
          }
        } catch (error) {
          console.error("Error checking terms acceptance:", error);
        }
      } else {
        console.warn("User or user.uid is undefined");
      }
    };

    checkTermsAccepted();
  }, [sessionStorage.getItem("uid")]);

  return { showTermsDialog, setShowTermsDialog };
};

export default useTermsCheck;
