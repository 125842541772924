const snippets = require("../assets/data/MessageKeys.json");

export function getRandomSnippet(category) {
  if (!snippets[category]) {
    throw new Error(`Category "${category}" not found`);
  }

  const categorySnippets = snippets[category];
  const randomIndex = Math.floor(Math.random() * categorySnippets.length);
  return categorySnippets[randomIndex];
}
