import React from "react";

export const parseError = (errorMessage) => {
  // Your logic to parse the error message and return a string based on the error
  // Example:
  if (errorMessage.includes("auth/user-not-found")) {
    return "User not found. Please check your email and try again.";
  } else if (errorMessage.includes("auth/invalid-password")) {
    return "Invalid password. Please check your password and try again.";
  } else if (errorMessage.includes("auth/email-already-exists")) {
    return "Email already exists. Please use a different email.";
  } else if (errorMessage.includes("auth/email-already-in-use")) {
    return "Email already in use. Please use a different email.";
  } else if (errorMessage.includes("auth/invalid-email")) {
    return "Invalid email. Please check your email and try again.";
  } else if (errorMessage.includes("auth/wrong-password")) {
    return "Wrong password. Please check your password and try again.";
  } else {
    return errorMessage;
  }
};

const FirebaseErrorParser = () => {
  return null; // You can return null as this component doesn't render anything
};

export { FirebaseErrorParser };
