import React, { useRef } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../configuration/firebase";
import { UserAuth } from "../contexts/AuthContext";
import { doc } from "firebase/firestore";

const TermsDialog = ({ isOpen, onClose, user }) => {
  const cancelRef = useRef();
  const { logout } = UserAuth();

  const handleAccept = async () => {
    const userDocRef = doc(db, "users", user.uid);
    await updateDoc(userDocRef, { termsAccepted: serverTimestamp() });
    onClose();
  };

  const handleDecline = async () => {
    await logout();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleDecline}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="header">
            Accept Terms and Conditions
          </AlertDialogHeader>

          <AlertDialogBody color="text">
            Please accept the{" "}
            <Button
              variant="link"
              as="a"
              href="https://resumiq.ai/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </Button>{" "}
            to continue using the app.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant={"primary"} onClick={handleAccept} mr={3}>
              Accept
            </Button>
            <Button ref={cancelRef} onClick={handleDecline}>
              Decline
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default TermsDialog;
