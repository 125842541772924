import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Avatar,
  Button,
  VStack,
  Heading,
  Text,
  Divider,
  Link,
} from "@chakra-ui/react";
import { FiHome, FiSettings, FiTool, FiInfo, FiLogOut } from "react-icons/fi";
import logo from "../assets/resumiq-orange-black.png";
import { useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";

import { db } from "../configuration/firebase";
import { doc, onSnapshot } from "firebase/firestore";

const SideNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [credits, setCredits] = useState(null);

  const { user, logout } = UserAuth();

  const handleLogout = () => {
    logout();
  };

  const uid = user?.uid || sessionStorage.getItem("uid");

  useEffect(() => {
    if (uid) {
      const userDocRef = doc(db, "users", uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setCredits(data.credits || 0);
          setFirstName(data.firstName || "");
          setLastName(data.lastName || "");
          sessionStorage.setItem("credits", data.credits || 0);
          sessionStorage.setItem("reviewCount", data.reviewCount || 0);
        }
      });
      return () => unsubscribe();
    }
  }, [uid]);

  const fullName =
    sessionStorage.getItem("firstName") +
    " " +
    sessionStorage.getItem("lastName");

  return (
    <Flex
      direction="column"
      align="center"
      bg="white"
      color="white"
      height={{ base: "auto", md: "100vh" }}
      width={{ base: "100%", md: "250px" }}
      padding="20px"
      position={{ base: "relative", md: "fixed" }}
      boxShadow={"lg"}
      zIndex={1}
    >
      <Box mb={8}>
        <img src={logo} alt="Logo" width="150" />
      </Box>
      <VStack spacing={4} align="flex-start" width="full">
        <Button
          leftIcon={<FiHome />}
          // variant="stealth"
          width="full"
          justifyContent="flex-start"
          variant={location.pathname === "/home" ? "primary" : "stealth"}
          onClick={() => {
            navigate("/home");
          }}
        >
          Home
        </Button>

        <Button
          leftIcon={<FiSettings />}
          variant={location.pathname === "/settings" ? "primary" : "stealth"}
          width="full"
          justifyContent="flex-start"
          onClick={() => {
            navigate("/settings");
          }}
        >
          Settings
        </Button>
        <Button
          leftIcon={<FiTool />}
          variant={location.pathname === "/tools" ? "primary" : "stealth"}
          width="full"
          justifyContent="flex-start"
          onClick={() => {
            navigate("/tools");
          }}
        >
          Tools
        </Button>
        <Button
          leftIcon={<FiInfo />}
          variant={location.pathname === "/contact" ? "primary" : "stealth"}
          width="full"
          justifyContent="flex-start"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Support
        </Button>
        <Divider borderColor="gray.300" borderWidth="1px" />
        <Button
          leftIcon={<FiLogOut />}
          variant="stealth"
          width="full"
          justifyContent="flex-start"
          colorScheme={location.pathname === "/support" ? "teal" : "gray"}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </VStack>

      <Flex flexGrow={1} />
      <Divider borderColor="gray.300" borderWidth="1px" mb={4} />
      <Flex
        align="center"
        justifyContent="flex-start"
        width="full"
        as={Link}
        style={{ textDecoration: "none" }}
        onClick={() => {
          navigate("/settings");
        }}
      >
        <Avatar
          style={{ textDecoration: "none" }}
          ml={1}
          name={fullName}
          src="your-profile-url.jpg"
        />

        <Box>
          <Heading size="sm" color="text" ml={4}>
            {firstName} {lastName}
          </Heading>
          <Text color={"text"} fontSize={"13"} ml={4}>
            {credits} Reviews
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SideNavBar;
