"use client";

import { UserAuth } from "../../contexts/AuthContext";

import { parseError } from "../../tools/FirebaseErrorParser";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  Spinner,
  Divider,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react";

import logo from "../../assets/resumiq-orange-black-vertical.png";
import Banner from "../../components/Banner";
import GoogleSignInButton from "../../components/GoogleSignInButton";
import VerificationModal from "../../components/EmailVerificationModal";
import { auth } from "../../configuration/firebase";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firebaseError, setFirebaseError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { signIn, sendEmailVerificationCustom } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFirebaseError("");
    setLoading(true);
    try {
      await signIn(email, password);
      const user = auth.currentUser;
      if (user) {
        await user.reload();
        if (user.emailVerified) {
          setTimeout(() => {
            setLoading(false);
            navigate("/home");
          }, 1000);
        } else {
          setLoading(false);
          await sendEmailVerificationCustom(user);
          onOpen();
        }
      }
    } catch (e) {
      setLoading(false);
      setFirebaseError(parseError(e.message));
      console.log(e.message);
    }
  };

  return (
    <>
      <Banner />
      <Flex minH="100vh" align="center" justify="center" bg="primaryBackground">
        <VerificationModal isOpen={isOpen} onClose={onClose} />
        <Box width={"500px"} p={6}>
          <Stack align="center" mb={8}>
            <Box as="button" onClick={() => navigate("/")} cursor="pointer">
              <Image src={logo} width="200px" py={2} />
            </Box>
            <Heading fontSize="xl" color="heading" textAlign="center">
              Sign in to your account
            </Heading>
            <Text textAlign="center" color="text" fontSize="sm">
              Or{" "}
              <Link
                onClick={() => navigate("/register")}
                color="text"
                textDecoration={"underline"}
                _hover={{ textDecoration: "none" }}
              >
                create an account
              </Link>
            </Text>
          </Stack>
          <Box
            bg="secondaryBackground"
            p={8}
            borderWidth="1px"
            borderColor="#f8f8f8"
            boxShadow="md"
            borderRadius="xl"
          >
            <Stack spacing={4}>
              <GoogleSignInButton text="Sign in with Google" />
              <Flex align="center" pb={4}>
                <Divider borderColor="gray.300" />
                <Text px={2} color="gray.500" fontWeight="medium">
                  or
                </Text>
                <Divider borderColor="gray.300" />
              </Flex>
              {firebaseError && (
                <Box mb={4}>
                  <Text color="#E53A5A" fontSize="sm">
                    {firebaseError}
                  </Text>
                </Box>
              )}
              <FormControl id="email">
                <FormLabel color="text">Email address</FormLabel>
                <Input
                  type="email"
                  color="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel color="text">Password</FormLabel>
                <Input
                  type="password"
                  color="text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align="start"
                  justify="space-between"
                >
                  <Button
                    variant="link"
                    size={"sm"}
                    onClick={() => navigate("/resetPassword")}
                  >
                    Forgot password?
                  </Button>
                </Stack>
                <Button variant="primary" onClick={handleSubmit}>
                  {loading ? <Spinner size="sm" /> : "Sign in"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
