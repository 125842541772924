import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth } from "../configuration/firebase";
import axios from "axios";
import { apiUrl } from "../config";
import { useNavigate } from "react-router-dom";
import { db } from "../configuration/firebase";
import { doc, getDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const addUserToDB = async (
    firstName,
    lastName,
    email,
    uid,
    termsAccepted
  ) => {
    try {
      await axios.post(`${apiUrl}/addUser`, {
        firstName,
        lastName,
        email,
        uid,
        couponsRedeemed: [],
        termsAccepted,
      });
    } catch (error) {
      navigate("/generalerror");
    }
  };

  const createUser = async (firstName, lastName, email, password) => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const uid = userCredential.user.uid;
    const user = userCredential.user;
    await sendEmailVerification(user);
    await addUserToDB(firstName, lastName, email, uid, true);

    sessionStorage.setItem("uid", uid);
    sessionStorage.setItem("firstName", firstName);
    sessionStorage.setItem("lastName", lastName);

    setUser({
      uid,
      email,
      firstName,
      lastName,
    });

    return userCredential;
  };

  const signIn = async (email, password) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      // If Google is a sign-in method, redirect to Google Sign-In
      if (signInMethods.includes("google.com")) {
        // Directly call signInWithGoogle if Google is the sign-in method
        return await signInWithGoogle();
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;
      const userDoc = await getDoc(doc(db, "users", uid));
      const userData = userDoc.data();

      if (userData) {
        sessionStorage.setItem("uid", uid);
        sessionStorage.setItem("firstName", userData.firstName);
        sessionStorage.setItem("lastName", userData.lastName);
        sessionStorage.setItem("SignInMethod", "email");
        setUser({
          uid,
          email,
          firstName: userData.firstName,
          lastName: userData.lastName,
        });
      } else {
        console.log("No such document!");
      }

      return userCredential;
    } catch (error) {
      console.error("Error signing in:", error.message);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const uid = user.uid;
      const email = user.email;
      const firstName = user.displayName.split(" ")[0];
      const lastName = user.displayName.split(" ").slice(1).join(" ");

      const userDoc = await getDoc(doc(db, "users", uid));

      if (!userDoc.exists()) {
        await addUserToDB(firstName, lastName, email, uid, false);
      }

      sessionStorage.setItem("uid", uid);
      sessionStorage.setItem("firstName", firstName);
      sessionStorage.setItem("lastName", lastName);
      sessionStorage.setItem("SignInMethod", "google");
      setUser({
        uid,
        email,
        firstName,
        lastName,
      });
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      throw error;
    }
  };

  const logout = async () => {
    if (sessionStorage.length !== 0) {
      sessionStorage.clear();
      await signOut(auth);
      setUser(null);
      navigate("/signIn");
    }
  };

  const sendPasswordResetEmailCustom = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw error;
    }
  };

  const sendEmailVerificationCustom = async (user) => {
    try {
      await sendEmailVerification(user);
    } catch (error) {
      throw error;
    }
  };

  const getSignInMethods = async (email) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.includes("google.com")) {
        return "google";
      } else {
        return "email";
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken(true);
        sessionStorage.setItem("idToken", idToken);

        const uid = currentUser.uid;
        const userDoc = await getDoc(doc(db, "users", uid));
        const userData = userDoc.data();

        if (userData) {
          sessionStorage.setItem("uid", uid);
          sessionStorage.setItem("firstName", userData.firstName);
          sessionStorage.setItem("lastName", userData.lastName);
          setUser({
            uid,
            email: currentUser.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });
        }
      } else {
        logout();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        createUser,
        user,
        logout,
        signIn,
        signInWithGoogle,
        sendPasswordResetEmailCustom,
        sendEmailVerificationCustom,
        getSignInMethods,
      }}
    >
      {!loading && children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
