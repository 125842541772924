import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const NewReviewButton = ({ buttonText, variant, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const credits = sessionStorage.getItem("credits");
  const reviewCount = sessionStorage.getItem("reviewCount");

  const handleClick = () => {
    if (credits == 0) {
      onOpen();
      return;
    }
    if (window.Tally && credits == 1 && reviewCount < 3) {
      window.Tally.openPopup("mRLxVv", {
        onClose: () => {
          navigate("/form");
        },
        onSubmit: () => {
          navigate("/form");
        },
      });
    } else {
      navigate("/form");
    }
  };

  return (
    <>
      <Button
        colorScheme="blue"
        variant={variant}
        {...rest}
        onClick={handleClick}
      >
        {buttonText}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Insufficient Review Credits</ModalHeader>
          <ModalBody>
            Oops! It looks like you've run out of reviews. Don't worry, you can
            easily top up your balance to continue enjoying our product.
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"primary"}
              mr={3}
              onClick={() => navigate("/pricing")}
            >
              Top up now
            </Button>

            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewReviewButton;
