import React, { useEffect } from "react";

import Signin from "./pages/auth/Signin";
import Register from "./pages/auth/Register";
import Home from "./pages/Home";
import Multistep from "./pages/Multistep";
import Pricing from "./pages/Pricing";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Coupon from "./components/Coupon";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancellation from "./pages/PaymentCancellation";
import GeneralError from "./pages/GeneralError";
import PaymentFailure from "./pages/PaymentFailure";
import Contact from "./pages/Contact";
import Report from "./pages/Report";
import ProtectedRoute from "./components/ProtectedRoute";
import Settings from "./pages/Settings";
import Tools from "./pages/Tools";

import customTheme from "./design/customTheme";

import { ChakraProvider } from "@chakra-ui/react";
import { SessionProvider } from "./contexts/SessionContext";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContext";

const OLD_DOMAIN = "https://revyoo.io"; // replace with your actual old domain
const NEW_DOMAIN = "https://resumiq.ai"; // replace with your actual new domain

function App() {
  useEffect(() => {
    if (window.location.hostname === OLD_DOMAIN) {
      window.location.href = NEW_DOMAIN;
    }
  }, []);

  return (
    <div className="App">
      <ChakraProvider theme={customTheme}>
        <SessionProvider>
          <AuthContextProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/signIn" replace />} />
              <Route path="/resetPassword" element={<ResetPasswordPage />} />
              <Route path="/generalerror" element={<GeneralError />} />
              <Route path="/paymentfailure" element={<PaymentFailure />} />

              <Route
                path="/signIn"
                element={
                  <ProtectedRoute type="external">
                    <Signin />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/register"
                element={
                  <ProtectedRoute type="external">
                    <Register />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/home"
                element={
                  <ProtectedRoute type="internal">
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/form"
                element={
                  <ProtectedRoute type="internal">
                    <Multistep />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/report"
                element={
                  <ProtectedRoute type="internal">
                    <Report />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/coupon"
                element={
                  <ProtectedRoute type="internal">
                    <Coupon />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <ProtectedRoute type="internal">
                    <Settings />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/tools"
                element={
                  <ProtectedRoute type="internal">
                    {/* <UnderConstruction /> */}
                    <Tools />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/pricing"
                element={
                  <ProtectedRoute type="internal">
                    <Pricing />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/contact"
                element={
                  <ProtectedRoute type="internal">
                    <Contact />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/paymentsuccess"
                element={
                  <ProtectedRoute type="internal">
                    <PaymentSuccess />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/paymentcancel"
                element={
                  <ProtectedRoute type="internal">
                    <PaymentCancellation />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/paymentfailure"
                element={
                  <ProtectedRoute type="internal">
                    <PaymentFailure />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthContextProvider>
        </SessionProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;
