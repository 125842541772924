"use client";

import {
  Flex,
  Box,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import * as Yup from "yup";
import SideNavBar from "../components/SideNavBar";

import emailjs from "@emailjs/browser";

export default function Contact() {
  const toast = useToast();

  function sendEmail(values) {
    emailjs
      .send(
        "service_gtngvy8",
        "template_ub9c13e",
        {
          reason: values.subject,
          from_name: values.name,
          from_email: values.email,
          message: values.message,
        },
        {
          publicKey: "fgPDHjSYUi6hpUcHz",
        }
      )
      .then((response) => {
        toast({
          title: "Message sent!",
          description:
            "Thanks for reaching out. We'll get back to you shortly.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "There was an error sending your message.",
          description: "Please send an email directly to contact@resumiq.ai",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    subject: Yup.string(),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      sendEmail(values);
    },
  });

  return (
    <>
      <Flex direction={{ base: "column", md: "row" }}>
        <SideNavBar />
        <Box flex="1" bg="primaryBackground" minH={"100vh"}>
          <Box maxW="600px" mx="auto" p={4}>
            <Heading as="h1" mb={6} textAlign="left" color={"text"} pt={10}>
              Contact Us
            </Heading>
            <Box
              bg="white"
              p={10}
              borderRadius="xl"
              boxShadow="md"
              borderWidth={"1px"}
              borderColor={"#f8f8f8"}
            >
              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  mb={4}
                  isInvalid={formik.touched.name && !!formik.errors.name}
                >
                  <FormLabel htmlFor="name" color={"text"}>
                    Name
                  </FormLabel>
                  <Input
                    borderRadius={"xl"}
                    id="name"
                    name="name"
                    type="text"
                    bg="primaryBackground"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>

                <FormControl
                  mb={4}
                  isInvalid={formik.touched.email && !!formik.errors.email}
                >
                  <FormLabel htmlFor="email" color={"text"}>
                    Email
                  </FormLabel>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    bg="primaryBackground"
                    borderRadius={"xl"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl mb={4}>
                  <FormLabel htmlFor="subject" color={"text"}>
                    Subject
                  </FormLabel>
                  <Input
                    id="subject"
                    name="subject"
                    type="text"
                    bg="primaryBackground"
                    borderRadius={"xl"}
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                  />
                </FormControl>

                <FormControl
                  mb={4}
                  isInvalid={formik.touched.message && !!formik.errors.message}
                >
                  <FormLabel htmlFor="message" color={"text"}>
                    Message
                  </FormLabel>
                  <Textarea
                    id="message"
                    name="message"
                    bg="primaryBackground"
                    borderColor={"lightGray"}
                    borderRadius={"xl"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                </FormControl>

                <Button type="submit" variant={"primary"} width="full" mt={4}>
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
