"use client";

import React from "react";
import {
  Heading,
  FormControl,
  GridItem,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";

const ResumeForm = ({ onChange }) => {
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    onChange((prevFormValues) => ({ ...prevFormValues, [id]: value }));
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        Let's see your resume.
      </Heading>
      <FormControl as={GridItem} colSpan={6}>
        <FormLabel htmlFor="resume" fontSize="sm" fontWeight="md" mt="2%">
          Resume
        </FormLabel>
        <Textarea
          id="resume"
          placeholder="Paste your entire resume contents here."
          sx={{
            "::placeholder": {
              color: "darkGray", // Use your desired color here
            },
          }}
          rows={15}
          shadow="sm"
          borderColor="lightGray"
          focusBorderColor="primaryHighlight"
          fontSize={{
            sm: "sm",
          }}
          onChange={handleInputChange}
        />
      </FormControl>
    </>
  );
};

export default ResumeForm;
