import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState(null);

  const updateSessionData = (data) => {
    setSessionData(data);
  };

  const clearSessionData = () => {
    setSessionData(null);
  };

  return (
    <SessionContext.Provider
      value={{ sessionData, updateSessionData, clearSessionData }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  return useContext(SessionContext);
};
