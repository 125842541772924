import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Switch,
  FormControl,
  FormLabel,
  Text,
  FormHelperText,
  Spinner,
  Container,
} from "@chakra-ui/react";
import ResetPassword from "../components/ResetPassword";
import Coupon from "../components/Coupon";
import { useNavigate } from "react-router-dom";
import SideNavBar from "../components/SideNavBar";

import { db } from "../configuration/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { UserAuth } from "../contexts/AuthContext";

const Settings = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState({
    product: true,
    report: true,
    resources: true,
  });
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchNotifications = async () => {
      const userId = sessionStorage.getItem("uid");

      try {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          const currentNotifications = userData.notifications || {
            product: true,
            report: true,
            resources: true,
          };

          setNotifications(currentNotifications);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleSwitchChange = async (event) => {
    const { id } = event.target;
    const userId = sessionStorage.getItem("uid");

    try {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        const currentNotifications = userData.notifications || {};

        const newValue = !currentNotifications[id];

        await updateDoc(docRef, {
          [`notifications.${id}`]: newValue,
        });

        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          [id]: newValue,
        }));
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  if (loading) {
    return (
      <Box
        bg="primaryBackground"
        minH="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner size="xl" color="primaryHighlight" />
      </Box>
    );
  }

  return (
    <>
      <Box bg={"primaryBackground"} minH={"100vh"} color={"text"}>
        <Flex direction={{ base: "column", md: "row" }}>
          <SideNavBar />
          <Box
            bg="primaryBackground"
            minH="100vh"
            ml={{ base: 0, md: "250px" }}
            flex="1"
          >
            <Container maxW={"3xl"} margin="auto" direction={"column"} mb={10}>
              <Heading fontWeight={"bold"} color={"heading"} mb={10} pt={10}>
                Settings
              </Heading>

              <Flex
                mb={5}
                direction={"column"}
                p={6}
                border="1px"
                borderRadius="xl"
                // mb={10}

                bg="white"
                boxShadow="md"
                borderWidth={"1px"}
                borderColor={"#f8f8f8"}
              >
                <Heading size="md" mb={5}>
                  Password Management
                </Heading>
                {sessionStorage.getItem("SignInMethod") == "google" && (
                  <Text>
                    You are signed in with your Google account on Resumiq.
                    Please visit your Google Account settings to update your
                    password.
                  </Text>
                )}
                {!(sessionStorage.getItem("SignInMethod") == "google") && (
                  <ResetPassword />
                )}
              </Flex>

              <Box
                p={4}
                border="1px"
                borderRadius="xl"
                mb={5}
                bg="white"
                boxShadow="md"
                borderWidth={"1px"}
                borderColor={"#f8f8f8"}
              >
                <Heading size="md" mb={4}>
                  Notifications
                </Heading>
                <FormControl mb={4}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel htmlFor="email-reports" mb="0">
                        Email Reports
                      </FormLabel>
                      <FormHelperText>
                        Have your resume reports emailed to you for easy
                        retrieval and access at any time.
                      </FormHelperText>
                    </Box>
                    <Switch
                      id="report"
                      ml={4}
                      isChecked={notifications.report}
                      onChange={handleSwitchChange}
                    />
                  </Flex>
                </FormControl>
                <FormControl mb={4}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel htmlFor="education-resources" mb="0">
                        Education and Resources
                      </FormLabel>
                      <FormHelperText>
                        Get the most from your Resumiq subscription with helpful
                        content and resources to help in your job search.
                      </FormHelperText>
                    </Box>
                    <Switch
                      id="resources"
                      ml={4}
                      isChecked={notifications.resources}
                      onChange={handleSwitchChange}
                    />
                  </Flex>
                </FormControl>
                <FormControl mb={4}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel htmlFor="product-news-promotions" mb="0">
                        Product Updates and Promotions
                      </FormLabel>
                      <FormHelperText>
                        Learn about the latest Resumiq product updates, features
                        and promotions as soon as they launch.
                      </FormHelperText>
                    </Box>
                    <Switch
                      id="product"
                      ml={4}
                      isChecked={notifications.product}
                      onChange={handleSwitchChange}
                    />
                  </Flex>
                </FormControl>
              </Box>

              <Box
                p={6}
                border="1px"
                borderRadius="xl"
                bg="white"
                boxShadow="md"
                borderWidth={"1px"}
                borderColor={"#f8f8f8"}
                mb={5}
              >
                <Heading mb={5} size="md">
                  Review Credits
                </Heading>
                <Coupon />
                <Text textAlign={"center"} my={2}>
                  Or
                </Text>
                <Box width={{ base: "100%", md: "50%" }} m="auto">
                  <Button
                    alignSelf={"center"}
                    width={"100%"}
                    variant={"outline"}
                    onClick={() => navigate("/pricing")}
                  >
                    Purchase more reviews
                  </Button>
                </Box>
              </Box>
              {/* </Flex> */}

              <Box
                p={4}
                border="1px"
                borderRadius="xl"
                bg="white"
                boxShadow="md"
                borderWidth={"1px"}
                borderColor={"#f8f8f8"}
              >
                <Heading size="md" mb={4}>
                  Legal
                </Heading>
                <Button
                  as={"a"}
                  fontWeight={"400"}
                  variant={"link"}
                  href="https://resumiq.ai/privacy"
                >
                  Privacy Policy
                </Button>
                <br />
                <Button
                  as={"a"}
                  fontWeight={"400"}
                  variant={"link"}
                  href="https://resumiq.ai/terms"
                >
                  Terms of Service
                </Button>
              </Box>
            </Container>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Settings;
