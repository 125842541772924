"use client";
import React from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
} from "@chakra-ui/react";
import SideNavBar from "../components/SideNavBar";
import UserReviewsTable from "../components/UserReviewsTable";

import { FaPlus, FaRegLightbulb } from "react-icons/fa6";
import useTermsCheck from "../hooks/useTermsCheck";
import TermsDialog from "../components/TermsDialog";

import { FcUpload, FcDocument, FcInspection } from "react-icons/fc";

import { useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import NewReviewButton from "../components/NewReviewButton";

const Card = ({ heading, description, icon }) => {
  return (
    <Box
      w={"full"}
      overflow="hidden"
      p={5}
      bg="secondaryBackground"
      borderWidth={"1px"}
      borderColor={"#f8f8f8"}
      boxShadow="md"
      borderRadius="xl"
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"heading"}
          rounded={"full"}
          bg={"gray.100"}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md" color={"heading"} align={"left"}>
            {heading}
          </Heading>
          <Text mt={1} color={"text "} fontSize={"sm"} align={"left"}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};

export default function Home() {
  const { showTermsDialog, setShowTermsDialog } = useTermsCheck();
  const { user } = UserAuth();
  const uid = user?.uid || sessionStorage.getItem("uid");
  const navigate = useNavigate();

  const firstName = sessionStorage.getItem("firstName");

  return (
    <>
      {user && (
        <TermsDialog
          isOpen={showTermsDialog}
          onClose={() => setShowTermsDialog(false)}
          user={user}
        />
      )}
      <Flex direction={{ base: "column", md: "row" }}>
        <SideNavBar />

        <Box
          bg="primaryBackground"
          minH="100vh"
          flex="1"
          ml={{ base: 0, md: "250px" }}
          px={10}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            maxW={"6xl"}
            textAlign={"left"}
            pb={10}
            margin="auto"
          >
            <Heading flex={1} pt={10} fontWeight={"bold"} color={"heading"}>
              {firstName}'s Dashboard
            </Heading>

            <NewReviewButton
              buttonText={"Tailor a resume"}
              variant={"primary"}
              leftIcon={<FaPlus />}
              width={"200px"}
              mt={10}
              size="md"
            />
          </Flex>

          {/* <Flex
            as={Container}
            maxW="6xl"
            justifyContent="space-between"
            gap={10}
            mb={10}
            p={0}
          >
            <Box
              p="5"
              bg="secondaryBackground"
              shadow="lg"
              borderRadius="lg"
              flex="1"
            >
              <Stat>
                <StatLabel>Reviews</StatLabel>
                <StatNumber>15</StatNumber>
              </Stat>
            </Box>
            <Box
              p="5"
              bg="secondaryBackground"
              shadow="lg"
              borderRadius="lg"
              flex="1"
            >
              <Stat>
                <StatLabel>Roles Tailored</StatLabel>
                <StatNumber>7</StatNumber>
              </Stat>
            </Box>
            <Box
              p="5"
              bg="secondaryBackground"
              shadow="lg"
              borderRadius="lg"
              flex="1"
            >
              <Stat>
                <StatLabel>Streak</StatLabel>
                <StatNumber>5 Days</StatNumber>
              </Stat>
            </Box>
            <Box
              p="5"
              bg="secondaryBackground"
              shadow="lg"
              borderRadius="lg"
              flex="1"
            >
              <Stat>
                <StatLabel>Average Match Score</StatLabel>
                <StatNumber>7%</StatNumber>
              </Stat>
            </Box>
          </Flex> */}
          <Box
            maxW={"6xl"}
            bg="secondaryBackground"
            p={5}
            borderRadius="xl"
            boxShadow="md"
            borderWidth={"1px"}
            borderColor={"#f8f8f8"}
            margin={"auto"}
          >
            <Heading color={"heading"} fontSize="25" pb={10}>
              Past Reviews
            </Heading>

            <UserReviewsTable />
          </Box>

          <Flex
            direction={"column"}
            maxW={"6xl"}
            mt={12}
            margin={"auto"}
            // as={Container}
          >
            <Flex
              direction={{ base: "column", lg: "row" }}
              maxW={"6xl"}
              textAlign={"left"}
              mb={10}
              pt={10}
            >
              <Box flex={1} pr={20}>
                <Heading
                  pt={5}
                  fontSize={"25"}
                  fontWeight={"bold"}
                  color={"heading"}
                >
                  Upcoming Features
                </Heading>
                <Text pt={2} fontSize={17}>
                  We're working hard to bring you exciting new features to make
                  your job search smoother!
                </Text>
              </Box>

              <Button
                leftIcon={<FaRegLightbulb />}
                variant={"outline"}
                size={"md"}
                mt={10}
                data-tally-open="nPld1B"
                data-tally-layout="modal"
                data-tally-width="800"
                data-tally-hide-title="1"
                data-tally-emoji-animation="tada"
              >
                Request a feature
              </Button>
            </Flex>
            <Grid
              templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
              gap={8}
              pb={20}
            >
              <Card
                heading={"Resume Upload"}
                icon={<Icon as={FcUpload} w={8} h={8} />}
                description={
                  "Soon you'll have the option to upload your resume as an input method."
                }
                buttonText={"Let's go"}
                isDisabled={false}
                href={"/form"}
              />
              <Card
                heading={"Cover Letter Generator"}
                icon={<Icon as={FcDocument} w={8} h={8} />}
                description={
                  "You'll be able have a tailored cover letter generated at the click of a button."
                }
                buttonText={"Coming soon"}
                isDisabled={true}
                href={"/form"}
              />
              <Card
                heading={"ATS and Format Checker"}
                icon={<Icon color={"#0077B5"} as={FcInspection} w={8} h={8} />}
                description={
                  "Not only is the content important for passing ATS, but the format is too. We'll have a checker for that."
                }
                buttonText={"Coming soon"}
                isDisabled={true}
                href={"#"}
              />
            </Grid>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}
