import React from "react";
import ResetPassword from "../components/ResetPassword";
import { Heading, Box, Flex, Stack, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/resumiq-orange-black-vertical.png";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  return (
    <Box bg="primaryBackground" minH="100vh">
      <Stack align="center" mb={8} pt={20}>
        <Box as="button" onClick={() => navigate("/")} cursor="pointer">
          <Image src={logo} width="200px" py={2} />
        </Box>
        <Heading fontSize="xl" color="heading" textAlign="center">
          Reset your password
        </Heading>

        <Flex justify="center" mt={8}>
          <Box
            bg="secondaryBackground"
            p={6}
            width={"500px"}
            borderWidth={"1px"}
            borderColor={"#f8f8f8"}
            boxShadow="md"
            borderRadius="xl"
            mx={4}
          >
            <ResetPassword />
          </Box>
        </Flex>
      </Stack>
    </Box>
  );
};

export default ResetPasswordPage;
