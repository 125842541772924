import React from "react";
import { UserAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../configuration/firebase";

const ProtectedRoute = ({ children, type }) => {
  const { user } = UserAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (type === "internal" && !user) {
      navigate("/signIn");
    } else if (type === "external" && user && auth.currentUser.emailVerified) {
      navigate("/home");
    }
  }, [user, type, navigate]);

  return children;
};

export default ProtectedRoute;
