"use client";
import React, { useState } from "react";

import {
  Button,
  FormControl,
  Input,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { UserAuth } from "../contexts/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../config";

const Coupon = () => {
  const toast = useToast();
  const user = UserAuth();
  const navigate = useNavigate();
  const [couponCode, setCouponCode] = useState("");
  const [isRedeeming, setIsRedeeming] = useState(false);
  const [error, setError] = useState("");

  const handleCouponRedemption = async () => {
    if (!couponCode.trim()) {
      setError("This field cannot be empty.");
      return;
    }

    setError("");
    setIsRedeeming(true);
    const uid = sessionStorage.getItem("uid");
    const idToken = sessionStorage.getItem("idToken");

    await axios
      .post(
        `${apiUrl}/validateCoupon?couponCode=${couponCode}&uid=${uid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.valid == "yes") {
          toast({
            title: "Reviews successfully redeemed.",
            description:
              response.data.credits +
              " Review credits have been added to your acccount. Happy reviewing!",
            status: "success",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: "Reviews could not be redeemed.",
            description: (
              <>
                You may be using an invalid or previously redeemed coupon code.
                If you believe this is an error, contact us at{" "}
                <Link color={"white"} href="mailto:contact@resumiq.ai">
                  contact@resumiq.ai
                </Link>
              </>
            ),
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .catch((error) => {
        navigate("/generalerror");
      })
      .finally(() => {
        setIsRedeeming(false);
      });
  };
  return (
    <>
      <Text pb="1">Coupon Code</Text>
      {error && (
        <Text color="red.500" fontSize="sm">
          {error}
        </Text>
      )}
      <Stack spacing={3} direction="row">
        <FormControl id="coupon">
          <Input
            type="text"
            color="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
        </FormControl>
        <Stack>
          <Button
            variant={"primary"}
            isLoading={isRedeeming}
            onClick={handleCouponRedemption}
            width={"100px"}
          >
            Redeem
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Coupon;
