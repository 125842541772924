"use client";
import React, { useState } from "react";

import {
  Button,
  FormControl,
  Input,
  Stack,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { UserAuth } from "../contexts/AuthContext";

const ResetPassword = () => {
  const { sendPasswordResetEmailCustom } = UserAuth(); // Use the correct function name
  const toast = useToast();
  const [loading, setLoading] = useState(false); // State for loading spinner

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handlePasswordReset = async (email) => {
    setLoading(true); // Set loading to true when the process starts
    try {
      await sendPasswordResetEmailCustom(email);
      toast({
        title: "Password reset link sent.",
        description:
          "Check your email for a link and instructions to reset your password.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title:
          "Error sending password reset link. Please try again later or contact us at contact@resumiq.ai.",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setLoading(false); // Set loading to false when the process ends
    }
  };

  return (
    <>
      <Text pb="1">Email Address</Text>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handlePasswordReset(values.email);
        }}
      >
        <Form>
          <Stack spacing={3} direction={"row"} align="center">
            <Field name="email">
              {({ field }) => (
                <FormControl id="email">
                  <Input {...field} type="email" color="text" />
                </FormControl>
              )}
            </Field>
            <Button
              variant={"primary"}
              type="submit"
              width={"100px"}
              isDisabled={loading} // Disable button when loading
            >
              {loading ? <Spinner size="sm" /> : "Reset"}
            </Button>
          </Stack>
          <ErrorMessage name="email">
            {(msg) => <div style={{ color: "#E53A5A" }}>{msg}</div>}
          </ErrorMessage>
        </Form>
      </Formik>
    </>
  );
};

export default ResetPassword;
